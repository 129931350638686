import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";

export interface WishlistItems {
  live_stream_ids: LiveStreamId[]
  product_ids: ProductId[]
}

export interface LiveStreamId {
  favourite_id: number
  live_stream_id: number
}

export interface ProductId {
  favourite_id: number
  product_id: number
}

export interface OrderList {
  data: Root[]
}

export interface Root {
  id: string
  type: string
  attributes: Attributes
}

export interface Attributes {
  id: number
  order_management_order_id: number
  quantity: number
  status: string
  catalogue_id: number
  catalogue_variant_id: number
  placed_at: string
  confirmed_at: any
  in_transit_at: any
  delivered_at: any
  cancelled_at: any
  refunded_at: any
  manage_placed_status: boolean
  manage_cancelled_status: boolean
  created_at: string
  updated_at: string
  unit_price: string
  total_price: string
  tax: string
  discount: string
  grand_total: string
  order_number: string
  order_item_status: OrderItemStatus
  shipment_details: ShipmentDetails
  order_statuses: any
  catalogue: Catalogue
  catalogue_variant: CatalogueVariant
}

export interface OrderItemStatus {
  id: number
  status: string
}

export interface ShipmentDetails {
  id: number
  account_id: number
  address: string
  name: string
  flat_no: string
  pin_code: string
  mobile: string
  deleted_at: any
  latitude: any
  longitude: any
  residential: boolean
  city: string
  state_code: any
  country_code: any
  state: string
  country: string
  address_line_2: any
  address_type: string
  address_for: string
  is_default: boolean
  landmark: any
  created_at: string
  updated_at: string
  email: string
}

export interface Catalogue {
  id: string
  type: string
  attributes: Attributes2
}

export interface Attributes2 {
  category_id: number
  sub_category_id: number
  title: string
  description: string
  status: string
  mrp: number
  selling_price: number
  ask_price: number
  bargain: boolean
  on_order: boolean
  is_ask_price: boolean
  is_brand: boolean
  sku: string
  product_images: ProductImage[]
  inventory_details: InventoryDetails
}

export interface ProductImage {
  id: number
  url: string
}

export interface InventoryDetails {
  id: number
  fullfilment_by: string
  procurement_type: string
  procurement_sla: string
  account_id: number
  stock_quantity: number
  catalogue_id: number
  created_at: string
  updated_at: string
}

export interface CatalogueVariant {
  id: string
  type: string
  attributes: Attributes3
}

export interface Attributes3 {
  id: number
  catalogue_id: number
  catalogue_variant_color: CatalogueVariantColor
  catalogue_variant_size: CatalogueVariantSize
  price: string
  stock_qty: number
  on_sale: boolean
  sale_price: string
  discount_price: any
  length: any
  breadth: any
  height: any
  created_at: string
  updated_at: string
  block_qty: any
  product_images: ProductImage2[]
  product_videos: ProductVideo[]
}

export interface CatalogueVariantColor {
  id: number
  name: string
  created_at: string
  updated_at: string
}

export interface CatalogueVariantSize {
  id: number
  name: string
  created_at: string
  updated_at: string
}

export interface ProductImage2 {
  id: number
  url: string
}

export interface ProductVideo {
  id: number
  url: string
}

interface CountryAttributes {
  name: string;
  emoji_flag: string;
  country_code: string;
}

interface CountryData {
  id: string;
  type: string;
  attributes: CountryAttributes;
}

interface ApiPayloadType{
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object | string;
  type?: string;
}

interface FollowAccount {
  id: number;
  first_name: string | null;
  full_phone_number: string;
  country_code: number | null;
  phone_number: number | null;
  email: string;
  activated: boolean;
  device_id: string | null;
  unique_auth_id: string;
  password_digest: string;
  created_at: string;
  updated_at: string;
  user_name: string;
  platform: string | null;
  user_type: string | null;
  app_language_id: number | null;
  last_visit_at: string | null;
  is_blacklisted: boolean;
  suspend_until: string | null;
  status: string;
  stripe_id: string | null;
  stripe_subscription_id: string | null;
  stripe_subscription_date: string | null;
  role_id: number;
  full_name: string;
  email_verified: boolean;
  phone_verified: boolean;
  business_name: string | null;
  business_type: string | null;
  admin_verified: boolean;
  updated_mobile_number: string | null;
  is_disabled: boolean;
  my_bio: string | null;
  updated_email: string | null;
  business_popup: boolean;
  password_digests: any[];
  shipment_type: string;
  same_drop_off: boolean;
  marked_destroy: boolean;
  upi_id: string | null;
  qr_generated: boolean;
  latitude: number | null;
  longitude: number | null;
  current_city: string | null;
  store_type: string;
  can_livestream: boolean | null;
  instagram_stream_url: string | null;
  facebook_stream_url: string | null;
  youtube_stream_url: string | null;
}

interface FollowAttributes {
  id: number;
  account_id: number;
  followable_id: number;
  created_at: string;
  updated_at: string;
  account: FollowAccount;
}

interface FollowData {
  id: string;
  type: string;
  attributes: FollowAttributes;
}

interface Meta {
  message: string;
  total_following: number;
}

interface FollowResponse {
  data: FollowData[];
  meta: Meta;
}

interface FollowResponseModalData {
  data: FollowData[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history?: any
  classes?: any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  tabValue: number
  openModalFollowing: boolean
  openModalFollowers: boolean
  openModalFilter: boolean
  openModalOffer: boolean
  buyerProfileData: any
  buyerProfileAddressData: any
  username: string
  otpStateEmail: string
  otpStateMobile: string
  dataSource: CountryData[]
  countrySelectedValue: any
  openModalMobile: boolean
  openModalDisableAccout: boolean
  openModalDeleteAccount: boolean
  openModalChangeEmail: boolean
  openModalChangePassword: boolean
  openModalChangeAddress: boolean
  openModalEmailOtp: boolean
  openModalMobileOtp: boolean
  openModalEnterChangePassword: boolean
  openModalPasswordSuccessfull: boolean
  openModalAddressSuccessfull: boolean
  showCurrenPassword: boolean
  showNewPassword: boolean
  showReNewPassword: boolean
  buyerProfileImg: any
  buyerProfileImgFile:any
  buyerProfileBannerImgFile:any
  openModalBio: boolean
  openModalUsername: boolean
  openTunedBox: boolean

  phoneError: string
  mobileNumber: string
  mobileNumberCopy: string
  otpError: string
  otp: string
  pwdError: string
  password: string
  currentPwdError: string
  currentPassword: string
  currentPasswordCopy: string
  newPwdError: string
  newPassword: string
  newPasswordCopy: string
  reNewPasswordError: string
  reNewPassword: string
  emailError: string
  email: string,
  emailCopy: string
  addressline1Error: string
  addressline1: string
  addressline2Error: string
  addressline2: string
  pincodeError: string
  pincode: string
  cityError: string
  city: string
  stateError: string
  state: any
  countryError: string
  country: any
  addressError: string
  profileBio: string
  profileBioError: string
  profileUsername: string
  profileUsernameError: string

  updateEmailToken: string
  updateMobileToken: string
  subCategoryData: any
  subCategoryID: string
  wishListProduct: ProductId[]
  message: string;
  messageModel: boolean;
  countryList: any;
  stateList: any;
  cityList: any;
  imageError: string;
  orderList: Root[],
  orderType: string
  dateType: string
  orderSearch: string
  errorModal: boolean;
  sallerFollowingData: FollowResponse,
  searchFollowList: string,
  sallerFollowingModalData: FollowResponseModalData;
  hideShow: number
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBuyerProfileApiId: string = ""
  getBuyerProfileAddressApiId: string = ""
  countryCodeApiCallId: string = ""
  updateBuyerProfileAddressApiId: string = ""
  updateBuyerProfileImage: string = ""
  updateBuyerProfileBannerImage: string = ""
  updateEmail: string= ""
  updateEmailTokenVerify: string= ""
  updateMobile: string= ""
  updateMobileTokenVerify: string= ""
  updateBuyerPassword: string= ""
  updateProfileBio: string= ""
  updateProfileUsername: string= ""
  getSubCategoryApiId: string = ""
  getSubCategoryDataApiId: string = ""
  apiAddToWishList: string = '';
  apiDeleteFromWishList: string = ''
  getCountryListApiCallId: string = ''
  getStateListApiCallId: string = ''
  getCityListApiCallId: string = ''
  getOrderListApiCallId: string = ''
  getFollowSellerApiCallID: string = ''
  unFollowSellerApiCallID: string = ''
  followSellerApiCallID: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      tabValue: 0,
      openModalFollowing: false,
      openModalFollowers: false,
      openModalFilter: false,
      openModalOffer: false,
      buyerProfileData: null,
      buyerProfileAddressData: null,
      username: "",
      otpStateEmail: "",
      otpStateMobile: "",
      dataSource: [],
      countrySelectedValue: {value:""},
      openModalMobile: false,
      openModalDisableAccout: false,
      openModalDeleteAccount: false,
      openModalChangeEmail: false,
      openModalChangePassword: false,
      openModalChangeAddress: false,
      openModalEmailOtp: false,
      openModalMobileOtp: false,
      openModalEnterChangePassword: false,
      openModalPasswordSuccessfull: false,
      openModalAddressSuccessfull: false,
      showCurrenPassword: false,
      showNewPassword: false,
      showReNewPassword: false,
      buyerProfileImg: '',
      buyerProfileImgFile:null,
      buyerProfileBannerImgFile: null,
      openModalBio: false,
      openModalUsername: false,
      openTunedBox: false,

      phoneError: '',
      mobileNumber: '',
      mobileNumberCopy: '',
      otpError: '',
      otp: '',
      pwdError: '',
      password: '',
      currentPwdError: '',
      currentPassword: '',
      currentPasswordCopy: '',
      newPwdError: '',
      newPassword: '',
      newPasswordCopy: '',
      reNewPasswordError: '',
      reNewPassword: '',
      emailError: '',
      email: '',
      emailCopy: '',
      addressline1Error: '',
      addressline1: '',
      addressline2Error: '',
      addressline2: '',
      pincodeError: '',
      pincode: '',
      cityError: '',
      city: '',
      stateError: '',
      state: '',
      countryError: '',
      country: '',
      addressError: '',
      updateEmailToken: '',
      updateMobileToken: '',
      profileBio: '',
      profileBioError: '',
      profileUsername: '',
      profileUsernameError: '',
      subCategoryData: [],
      subCategoryID: '',
      wishListProduct: [],
      message: '',
      messageModel: false,
      countryList: [],
      stateList: [],
      cityList: [],
      imageError: '',
      orderList: [],
      orderType: '',
      dateType: '',
      orderSearch: '',
      errorModal: false,
      sallerFollowingData: {data:[],meta:{ message: "",
        total_following:0}},
      searchFollowList: "",
      sallerFollowingModalData: {data:[]},
      hideShow: 0,
      isLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.getBuyerProfileApiId:
          this.handlegetBuyerProfileApiId(responseJson)
          break;
        case this.getBuyerProfileAddressApiId:
          this.handlegetBuyerProfileAddressApiId(responseJson)
          break;
        case this.countryCodeApiCallId:
          this.handlecountryCodeApiCallId(responseJson)
          break;
        case this.updateBuyerProfileAddressApiId:
          this.handleupdateBuyerProfileAddressApiId(responseJson)
          break;
        case this.updateBuyerProfileImage:
          this.handleupdateBuyerProfileImage(responseJson)
          break;
        case this.updateBuyerProfileBannerImage:
          this.handleupdateBuyerProfileImage(responseJson)
          break;
        case this.updateEmail:
          this.setUpdateEmailData(responseJson);
          break;
        case this.updateEmailTokenVerify:
          this.checkEmailOtpVerify(responseJson);
          break;
        case this.updateMobile:
          this.setUpdateMobiledata(responseJson);
          break;
        case this.updateMobileTokenVerify:
          this.checkMobileOtpVerify(responseJson);
            break;
        case this.updateBuyerPassword:
          this.checkPasswordUpdate(responseJson);
          break;
        case this.updateProfileBio:
          this.checkProfileUpdate(responseJson);
          break;
        case this.updateProfileUsername:
          this.checkProfileUpdate(responseJson);
          break;
        case this.getSubCategoryApiId:
          this.setSubCategory(responseJson);
          break;
        case this.getSubCategoryDataApiId:
          this.setSubCategoryData(responseJson);
          break;
        case this.apiAddToWishList:
          this.checkItemAddedWishList(responseJson);
          break;
        case this.apiDeleteFromWishList:
          this.checkItemRemovedWishList(responseJson);
          break;
        case this.getCountryListApiCallId:
          this.setCountryList(responseJson);
          break;
        case this.getStateListApiCallId:
          this.setStateList(responseJson);
          this.getCityList();
          break;
        case this.getCityListApiCallId:
          this.setCityList(responseJson);
          break;
        case this.getOrderListApiCallId:
          this.setOrderList(responseJson);
          break;
          case this.getFollowSellerApiCallID:
            this.getFollowSellerCallback(responseJson);
            break;
            case this.unFollowSellerApiCallID:
            this.unFollowSellerCallback(responseJson);
            break;
            case this.followSellerApiCallID:
            this.getFollowSellerCallbackChange(responseJson);
            break;
            
        default:
          break;
      }

    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    keyboardType: "email-address",
    autoCompleteType: "email",
  };


  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => { this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.setState({ enableField: !this.state.enableField });
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = { source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = { onPress: () => this.doButtonPressed() };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => { 
    this.setState(
      { txtInputValue: text }
    ); };

  setEnableField = () => { this.setState({ 
    enableField: !this.state.enableField 
  });
  };

  // Customizable Area Start
  async componentDidMount() {
    this.getBuyerProfileData();
    this.getBuyerProfileAddressData();
    this.makeRemoteRequest();
    this.getBuyerSubcategoryData();
    this.getCountryList();
    this.getOrderList();
    this.getFollowSellerApiCall()
    const headingElement = document.getElementById("myref")
    if(headingElement) headingElement.scrollTo({top: 0,behavior: 'smooth'})
  }

  componentDidUpdate(prevProps:any, prevState:any) {
    // Check if searchFollowList has changed
    if (prevState.searchFollowList !== this.state.searchFollowList) {
      this.getFollowSellerApiCall();
    }
  }

  handlegetBuyerProfileApiId = (responseJson: any) => {
    if (responseJson.account && responseJson.account.data) {
      this.setState({
        buyerProfileData: responseJson.account.data
      })
    } else {
      this.setState({
        buyerProfileData: null
      })
    }
  }

  handlegetBuyerProfileAddressApiId = (responseJson: any) => {
    if (responseJson && responseJson.data?.attributes) {
      this.setState({
        buyerProfileAddressData: responseJson.data,
        addressline1: responseJson.data.attributes.address_line_1 ? responseJson.data.attributes.address_line_1 : "",
        addressline2: responseJson.data.attributes.address_line_2 ? responseJson.data.attributes.address_line_2 : "",
        pincode: responseJson.data.attributes.pin_code ? responseJson.data.attributes.pin_code : "",
        city: responseJson.data.attributes.city ? responseJson.data.attributes.city : "",
        state: responseJson.data.attributes.state ? responseJson.data.attributes.state : "",
        country: responseJson.data.attributes.country ? responseJson.data.attributes.country :"",
      }, ()=>{this.getStateList();})
    } else {
      this.setState({
        buyerProfileAddressData: null,
        addressline1: '',
        addressline2: '',
        pincode: '',
        city: '',
        state: '',
        country: '',
      })
    }
  }

  handlecountryCodeApiCallId = (responseJson: any) => {
    if (responseJson && responseJson.data) { 
      this.setState({
        dataSource: responseJson.data,
        countrySelectedValue: this.countryCodesSelected(responseJson.data)
      })
    } else {
      this.setState({
        dataSource: [],
        countrySelectedValue: {value:""}
      })
    }
  }

  handleupdateBuyerProfileAddressApiId = (responseJson:any) => {
    if (responseJson?.meta?.message == "Address Updated Successfully" || responseJson?.meta?.message == "Address Created Successfully") {
      this.handleCloseModalChangeAddress()
      this.handleOpenModalAddressSuccessfull();
    } else {
      this.setState({
        addressError: configJSON.AddressError
      });
    }
  }

  handleupdateBuyerProfileImage = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.getBuyerProfileData()
    } else {
      if (responseJson.errors[0]['Profile photo']) {
        this.setState({
          imageError: responseJson.errors[0]['Profile photo'],
          errorModal: true
        })
      } else {
        this.setState({
          imageError: responseJson.errors[0]['Background photo'],
          errorModal: true
        })
      }
    }
  }

  setUpdateEmailData = (responseJson: any) => {
    if (responseJson && responseJson.errors) {
      this.setError("emailError", configJSON.updateEmailError);
    } 
    if (responseJson && responseJson.meta) {
      this.handleCloseModalChangeEmail();
      this.setState({ updateEmailToken: responseJson.meta.token });
      this.handleOpenModalEmailOtp();
    } else {
      this.setError("emailError", configJSON.updateEmailError);
    }
  }

  checkEmailOtpVerify = (responseJson: any) => {
    if (responseJson && responseJson.errors) {
      this.setError("otpError", configJSON.updateOTPError);
    }
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      this.handleCloseModalEmailOtp();
      this.getBuyerProfileData();
    }
  }

  setUpdateMobiledata = (responseJson: any) => {
    if (responseJson && responseJson.errors) {
      this.setError("phoneError", configJSON.updateMobileError);
    }
    if (responseJson && responseJson.meta) {
      this.setState({ updateMobileToken: responseJson.meta.token });
      this.handleOpenModalMobileOtp();
      this.handleCloseModalMobile();
    } else {
      this.setError("phoneError", "Mobile number has already been taken");
    }
  }

  checkMobileOtpVerify = (responseJson: any) => {
    if (responseJson && responseJson.errors) {
      this.setError("otpError", configJSON.updateOTPError);
    }
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      this.getBuyerProfileData();
      this.handleCloseModalMobileOtp();
    }
  }

  checkPasswordUpdate = (responseJson: any) => {
    this.setState({isLoading: false});
    if (responseJson.message == "Password changed successfully") {
      this.handleCloseModalEnterChangePassword();
      this.handleOpenModalPasswordSuccessfull();
    } else {
      this.setState({ reNewPasswordError: responseJson.message })
    }
  }

  checkProfileUpdate = async(responseJson: any) => {
    if (responseJson && responseJson.data) {
        this.getBuyerProfileData();
        this.handleCloseModalBio();
        this.handleCloseModalUsername();
        const buyerData = await getStorageData("Buyer_Data", true);
        buyerData.account.user_name = responseJson.data.attributes.user_name
        await setStorageData("Buyer_Data",JSON.stringify(buyerData));
    } else {
      this.setError("profileUsernameError", configJSON.updateUsernameError);
    }
  }

  setSubCategory = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        subCategoryID: responseJson.data.map((item: any) => item.id).join(',')
      }, () => {
        this.getSuggestionData();
      })
    }
  }

  setSubCategoryData = (responseJson: any) => {
    if (responseJson && responseJson.catalogues && responseJson.catalogues.data) {
      this.setState({
        subCategoryData: responseJson.catalogues.data
      })
    }
    if (responseJson && responseJson.wishlist_items && responseJson.wishlist_items.product_ids) {
      this.setState({
        wishListProduct: responseJson.wishlist_items.product_ids
      })
    }
  }

  closeMessageModal = () => {
    this.setState({
      messageModel: false
    })
  }

  checkItemAddedWishList = (responJson: any) => {
    if (responJson.message) {
      this.getSuggestionData();
      this.setState({ 
        message: responJson.message, 
        messageModel: true
      })
    } else {
      this.setState({ message: responJson.errors, messageModel: true })
    }
  }

  checkItemRemovedWishList = (responJson: any) => {
    if (responJson.message) {
      this.getSuggestionData();
      this.setState({ message: responJson.message, messageModel: true })
    } else {
      this.setState({ 
        message: responJson.errors[0], 
        messageModel: true
      })
    }
  }

  setCountryList = (responseJson: any) => {
    if (responseJson.countries) {
        this.setState({
            countryList: Object.entries(responseJson.countries).map(([slug, name]) => ({ slug, name })),
        });
    }
  }

  setStateList = (responseJson: any) => {
    if (responseJson.states) {
      this.setState({
        stateList: Object.entries(responseJson.states).map(([slug, name]) => ({ slug, name })),
        state: Object.entries(responseJson.states).map(([slug, name]) => ({ slug, name })).find((state: any) => state.name === this.state.state)?.name
      });
    }
  }

  setCityList = (responseJson: any) => {
    if (responseJson.cities) {
      this.setState({
        cityList: responseJson.cities,
        city: this.state.city
      });
    }
  }

  setOrderList = (responseJson: OrderList) => {
    if (responseJson.data) {
      this.setState({ orderList: responseJson.data }, () => { console.log('tttt', this.state.orderList) })
    }
  }
  
  checkProductStatus = (producArray: any, ID: any) => {
    const index = producArray.findIndex(
      (item: any) =>
        item.product_id === ID);
    if (index !== -1) {
      return true;
    } else {
      return false;
    }
  } 

  deleteProductItem = (producArray: any, ID: any) => {
    if (!Array.isArray(producArray)) return false;
    const index = producArray.find(
      (item: any) => item.product_id === ID)?.favourite_id;

    if (index) {
      this.deleteWishListItem(index)
    } else {
      return false;
    }
  }

  countryCodesSelected = (data: any[]) => {
    let defaultCountryCode = {
      value: "",
    }

    if (data.length) {
      const resultData = data.find((item: any) => item.attributes.country_code == "91")
      if (resultData && resultData.attributes.country_code) {
        defaultCountryCode.value = resultData.attributes.country_code
      }
    }
    return defaultCountryCode;
  };

  handleChange = (event: any, newValue: any) => {
    this.setState({ tabValue: newValue })
  }

  handleChangeCountry = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    this.setState({ countrySelectedValue: { value: event.target.value as string } });
  }

  handleOpenModalFollowing: any = () => {
    this.setState({ openModalFollowing: true });
  };

  handleCloseModalFollowing: any = () => {
    this.setState({ openModalFollowing: false, searchFollowList : "" });
    this.getFollowSellerApiCall()
  };

  handleOpenModalFollowers: any = () => {
    this.setState({ openModalFollowers: true });
  };

  handleCloseModalFollowers: any = () => {
    this.setState({ openModalFollowers: false });
  };

  handleOpenModalFilter: any = () => {
    this.setState({ openModalFilter: true });
  };

  handleCloseModalFilter: any = () => {
    this.getOrderList()
    this.setState({ openModalFilter: false });
  };

  handleClearFilter = () => {
    this.setState({
      orderType: '',
      dateType: '',
      openModalFilter: false
    },()=>{this.getOrderList()})
  }

  handleOpenModalOffer: any = () => {
    this.setState({ openModalOffer: true });
  };

  handleCloseModalOffer: any = () => {
    this.setState({ openModalOffer: false });
  };

  handleOpenModalMobile: any = () => {
    this.setState({ openModalMobile: true });
  }

  handleCloseModalMobile: any = () => {
    this.setState({
      openModalMobile: false,
      mobileNumber: '',
      phoneError: ''
    });
  }

  errorModalClose = () => {
    this.setState({ errorModal: !this.state.errorModal })
  }

  handleOpenModalDisableAccount: any = () => {
    this.setState({ openModalDisableAccout: true });
  }

  handleCloseModalDisableAccount: any = () => {
    this.setState({ openModalDisableAccout: false });
  }

  handleOpenModalDeleteAccount: any = () => {
    this.setState({ openModalDeleteAccount: true });
  }

  handleCloseModalDeleteAccount: any = () => {
    this.setState({
      openModalDeleteAccount: false,
      password: '',
      pwdError: ''
    });
  }

  handleOpenModalChangeEmail: any = () => {
    this.setState({ openModalChangeEmail: true });
  }

  handleCloseModalChangeEmail: any = () => {
    this.setState({
      openModalChangeEmail: false,
      email: '',
      emailError: ''
    });
  }

  handleOpenModalChangeAddress: any = () => {
    this.setState({ openModalChangeAddress: true });
  }

  handleCloseModalChangeAddress: any = () => {
    this.setState({
      openModalChangeAddress: false,
      addressline1Error: '',
      addressline2Error: '',
      pincodeError: '',
      cityError: '',
      stateError: '',
      countryError: '',
      addressError: ''
    }, () => {
      this.getBuyerProfileAddressData()
    });
  }

  handleOpenModalEmailOtp: any = () => {
    this.handleCloseModalChangeEmail();
    this.setState({ openModalEmailOtp: true });
  }

  handleCloseModalEmailOtp: any = () => {
    this.setState({
      openModalEmailOtp: false,
      otpError: '',
      otpStateEmail: ''
    });
  }

  handleOpenModalMobileOtp: any = () => {
    this.handleCloseModalMobile();
    this.setState({ openModalMobileOtp: true });
  }

  handleCloseModalMobileOtp: any = () => {
    this.setState({
      openModalMobileOtp: false,
      otpError: ''
    });
  }

  handleOpenModalEnterChangePassword: any = () => {
    this.setState({ openModalEnterChangePassword: true });
  }

  handleOpenModalBio: any = () => {
    this.setState({ openModalBio: true });
  }

  handleCloseModalBio: any = () => {
    this.setState({
      openModalBio: false,
      profileBioError: ''
    });
  }

  handleOpenModalUsername: any = () => {
    this.setState({ openModalUsername: true });
  }

  handleCloseModalUsername: any = () => {
    this.setState({ 
      openModalUsername: false,
      profileUsernameError: ''
    });
  }

  openStayTunedBox = () => {
    this.setState({
      openTunedBox: true
    });
  }
  closeTunedBox = () => {
    this.setState({
      openTunedBox: false
    })
  }

  handleOrderFilterByChange = (event: any) => {
    this.setState({
      orderType: event.target.value,
    });
  };

  handleDateFilterByChange = (event: any) => {
    this.setState({
      dateType: event.target.value,
    });
  };

  handleCloseModalEnterChangePassword: any = () => {
    this.setState({
      openModalEnterChangePassword: false,
      currentPassword: '',
      currentPwdError: '',
      showCurrenPassword: false,
      newPassword: '',
      newPwdError: '',
      showNewPassword: false,
      reNewPassword: '',
      reNewPasswordError: '',
      showReNewPassword: false
    });
  }

  handleOpenModalAddressSuccessfull: any = () => {
    this.setState({ openModalAddressSuccessfull: true });
  }

  handleCloseModalAddressSuccessfull: any = () => {
    this.setState({ openModalAddressSuccessfull: false });
  }

  handleNewPasswordShow: any = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword })
  }

  handleReNewPasswordShow: any = () => {
    this.setState({ showReNewPassword: !this.state.showReNewPassword })
  }

  handleCurrenPasswordShow: any = () => {
    this.setState({ showCurrenPassword: !this.state.showCurrenPassword })
  }

  handleCloseModalPasswordSuccessfull: any = () => {
    this.setState({ openModalPasswordSuccessfull: false });
  }

  handleOpenModalPasswordSuccessfull: any = () => {
    this.setState({ openModalPasswordSuccessfull: true });
  }

  handleOTPStateEmail = (val: any) => {
    this.setState({ otpStateEmail: val })
    this.setState({ otp: val })
  }

  handleOTPStateMobile = (val: any) => {
    this.setState({ otpStateMobile: val })
    this.setState({ otp: val })
  }

  setError = (stateKey: keyof S, errorMsg: string) => {
    this.setState((prevState) => ({
      ...prevState,
      [stateKey]: errorMsg,
    }));
  };

  handleMobileNumberChange = (event: any) => {
    const { value } = event.target;
    const limitedValue = value.slice(0, 10);
    this.setState({ 
      mobileNumber: limitedValue,
      mobileNumberCopy: limitedValue
    })
  }

  handlePasswordDeleteAccount = (event: any) => {
    const { value } = event.target;
    this.setState({ password: value })
    this.setState({ password: value })
  }

  handleCurrentPasswordChangePassword = (event: any) => {
    const { value } = event.target;
    this.setState({ currentPasswordCopy: value,
      currentPassword: value
    })
    this.setState({ currentPassword: value })
  }

  handleNewPasswordChangePassword = (event: any) => {
    const { value } = event.target;
    this.setState({
      newPasswordCopy: value,
      newPassword: value
    })
    this.setState({ 
      newPassword: value 
    })
  }

  handleReEnterNewPasswordChangePassword = (event: any) => {
    const { value } = event.target;
    this.setState({ 
      reNewPassword: value 
    })
    this.setState({ reNewPassword: value 
    })
  }

  handleChangeEmail = (event: any) => {
    const { value } = event.target;
    this.setState({ 
      email: value,
      emailCopy: value
     })
    this.setState({ email: value })
  };

  handleChangeOrderSearch = (event: any) => {
    const { value } = event.target;
    this.setState({ 
      orderSearch: value,
     }, () => {
      if (this.state.orderSearch === '') {
        this.getOrderList();
      }
     })
  };

  orderSearchSubmit = (e: any) => {
    e.preventDefault();
    this.getOrderList();
    this.setState({ openModalFilter: false })
  }

  handleChangeAddressline1 = (event: any) => {
    const { value } = event.target;
    this.setState({ addressline1: value })
    this.setState({ addressline1: value })
  };

  handleChangeAddressline2 = (event: any) => {
    const { value } = event.target;
    this.setState({ addressline2: value })
    this.setState({ addressline2: value })
  };

  handleChangePincode = (event: any) => {
    const { value } = event.target;
    this.setState({ pincode: value })
    this.setState({ pincode: value })
  };

  handleBuyerProfileImage = (event: any) => {
    const selectedFile: File = event.target.files[0];
    if (selectedFile instanceof Blob) {
      const reader = new FileReader();
      this.setState({
        buyerProfileImgFile: selectedFile,
      }, () => {
        this.updateBuyerProfileImg()
      });
      reader.readAsDataURL(selectedFile);
    }
  }

  handleBuyerBannerImage = (event: any) => {
    const selectedFile: File = event.target.files[0];
    if (selectedFile instanceof Blob) {
      const reader = new FileReader();
      this.setState({
        buyerProfileBannerImgFile: selectedFile,
      }, () => {
        this.updateBuyerProfileBannerImg()
      });
      reader.readAsDataURL(selectedFile);
    }
  }

  handleChangeProfileBio = (event: any) => {
    const { value } = event.target;
    this.setState({ profileBio: value.trim() })
  };

  handleChangeProfileUsername = (event: any) => {
    const { value } = event.target;
    this.setState({ profileUsername: value.trim() })
  };

  handleCountryChange = (event: any, newValue: any) => {
    this.setState({
      country: newValue,
      state: '',
      stateList: [],
      city: '',
      cityList: [],
    }, () => {
      newValue && this.getStateList();
    });
    this.setState({ countryError: "" });
  }

  handleStateChange = (event: any, newValue: any) => {
    this.setState({
      state: newValue,
      city: '',
      cityList: []
    }, () => {
      newValue && this.getCityList();
    });
    this.setState({ stateError: "" });
  }

  validatePassword = (): boolean => {
    const { password } = this.state;
    if (password === '') {
      this.setError("pwdError", configJSON.errorPassword);
      return false;
    } if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/.test(password)) {
      this.setError("pwdError", configJSON.errorPasswordNotValid);
      return false;
    }
    this.setState({ pwdError: "" });
    return true;
  };

  handleCityChange = (event: any, newValue: any) => {
    this.setState({
      city: newValue
    });
    this.setState({ cityError: "" });
  }

  validatePhone = (): boolean => {
    const { mobileNumber } = this.state;
    if (!mobileNumber || mobileNumber.trim().length !== 10) {
      this.setError("phoneError", configJSON.phoneError);
      return false;
    }
    this.setState({ phoneError: "" });
    return true;
  };

  validateNewPassword = (): boolean => {
    const { newPassword, reNewPassword } = this.state;
    if (newPassword === '') {
      this.setError("newPwdError", configJSON.errorPassword);
      return false;
    } if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/.test(newPassword)) {
      this.setError("newPwdError", configJSON.errorPasswordNotValid);
      return false;
    }
    this.setState({ newPwdError: "" });

    if (newPassword !== reNewPassword) {
      this.setError("reNewPasswordError", configJSON.confirmPwdError);
      return false;
    }
    this.setState({ reNewPasswordError: "" });

    return true;
  };

  validateCurrentPassword = (): boolean => {
    const { currentPassword } = this.state;
    if (currentPassword === '') {
      this.setError("currentPwdError", configJSON.errorPassword);
      return false;
    } if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/.test(currentPassword)) {
      this.setError("currentPwdError", configJSON.errorPasswordNotValid);
      return false;
    } 
    this.setState({ currentPwdError: "" });
    return true;
  };

  validateEmail = (): boolean => {
    const { email } = this.state;
    if (!email || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)) {
      this.setError("emailError", configJSON.emailError);
      return false;
    }
    this.setState({ emailError: "" });
    return true;
  }

  validateAddressline1 = (): boolean => {
    const { addressline1 } = this.state;
    if (!addressline1 || addressline1.trim().length < 3) {
      this.setError("addressline1Error", configJSON.addressline1Error);
      return false;
    }
    this.setState({ addressline1Error: "" });
    return true;
  }

  validateAddressline2 = (): boolean => {
    const { addressline2 } = this.state;
    if (!addressline2 || addressline2.trim().length < 3) {
      this.setError("addressline2Error", configJSON.addressline2Error);
      return false;
    }
    this.setState({ addressline2Error: "" });
    return true;
  }

  validatePincode = (): boolean => {
    const { pincode } = this.state;
    if (!pincode || pincode.length < 5) {
      this.setError("pincodeError", configJSON.pincodeError);
      return false;
    }
    this.setState({ pincodeError: "" });
    return true;
  }

  validateProfileBio = (): boolean => {
    const { profileBio } = this.state;
    if (!profileBio || profileBio.length < 3) {
      this.setError("profileBioError", configJSON.profileBioError);
      return false;
    }
    this.setState({ profileBioError: "" });
    return true;
  }

  validateProfileUsername = (): boolean => {
    const { profileUsername } = this.state;
    if (!profileUsername || profileUsername.length < 3) {
      this.setError("profileUsernameError", configJSON.profileUsernameError);
      return false;
    }
    this.setState({ profileBioError: "" });
    return true;
  }

  ValidateCountry = () => {
    const { country } = this.state;
    if (!country) {
      this.setError("countryError", configJSON.countryError);
      return false;
    } else {
      this.setState({ countryError: "" });
      return true;
    }
  }

  ValidateState = () => {
    const { state } = this.state;
    if (!state) {
      this.setError("stateError", configJSON.stateError);
      return false;
    } else {
      this.setState({ stateError: "" });
      return true;
    }
  }

  validateOtp = (): boolean => {
    const { otp } = this.state;
    const optNew = otp.trim();
    if (!optNew || optNew.length !== 6) {
      this.setError("otpError", configJSON.otpError);
      return false;
    }
    this.setState({ otpError: "" });
    return true;
  }

  ValidateCity = () => {
    const { city } = this.state;
    if (!city) {
      this.setError("cityError", configJSON.cityError);
      return false;
    } else {
      this.setState({ cityError: "" });
      return true;
    }
  }

  checkValidationModalMobile = () => {
    let phone = this.validatePhone();
    if (phone) {
      this.updateBuyerMobile()
    }
  }

  checkValidationModalEmail = () => {
    let email = this.validateEmail();
    if (email) {
      this.updateBuyerEmail()
    }
  }

  checkValidationOtpMobile = () => {
    let otp = this.validateOtp();
    if (otp) {
      this.buyerUpdateMobileTokenVerify()
    }
  }

  checkValidationOtpEmail = () => {
    let otp = this.validateOtp();
    if (otp) {
      this.buyerUpdateEmailTokenVerify();
    }
  }

  checkValidationDeleteAccount = () => {
    let password = this.validatePassword();
    if (password) {
      this.handleCloseModalDeleteAccount();
    }
  }

  checkValidationPasswordChange = () => {
    let currentPassword = this.validateCurrentPassword();
    let newPassword = false
    if (currentPassword) {
      newPassword = this.validateNewPassword();
    }
    if (currentPassword && newPassword) {
      this.setState({isLoading: true})
      this.updateSellerChangePassword();
    }
  }

  checkValidationAddressChange = () => {
    let addressline1 = this.validateAddressline1();
    let addressline2 = this.validateAddressline2();
    let pincode = this.validatePincode();
    let city = this.ValidateCity();
    let state = this.ValidateState();
    let country = this.ValidateCountry();
    if (addressline1 && addressline2 && pincode && country && city && state) {
      this.updateBuyerProfileAddressData();
    }
  }

  checkValidationBioChange = () => {
    let profileBio = this.validateProfileBio();
    if (this.state.profileBio === '') {
      this.setError("profileBioError", configJSON.profileBioError);
      return false;
    }
    if (profileBio) {
      this.updateSellerProfileBio()
    }
  }

  checkValidationUsernameChange = () => {
    let profileUsername = this.validateProfileUsername();
    if (this.state.profileUsername === '') {
      this.setError("profileUsernameError", configJSON.profileUsernameError);
      return false;
    }
    if (profileUsername) {
      this.updateSellerProfileUsername()
    }
  }

  getBuyerProfileData: any = async () => {
    const buyertoken = await getStorageData("buerLoginToken");
    if (buyertoken) {
      const header = {
        "token": buyertoken,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getBuyerProfileApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.buyerProfileAPIEndpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  getBuyerProfileAddressData: any = async () => {
    const buyertoken = await getStorageData("buerLoginToken");
    if (buyertoken) {
      const header = {
        "token": buyertoken,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getBuyerProfileAddressApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.buyerProfileAddressAPIEndpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  makeRemoteRequest: any = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.countryCodeApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetCountryCodes
    );

    const header = { "Content-Type": configJSON.contentTypeApiGetCountryCodes };

    requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiGetCountryCodesType );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateBuyerProfileAddressData: any = async () => {
    const buyertoken = await getStorageData("buerLoginToken");
    if (buyertoken) {
      const header = {
        "Content-Type": 'application/json',
        "token": buyertoken,
      };
      let address = {};
      if (this.state.buyerProfileAddressData && this.state.buyerProfileAddressData.id) {
        address = {
          "id": this.state.buyerProfileAddressData.id,
          "address": {
            "address_line_1": this.state.addressline1,
            "address_line_2": this.state.addressline2,
            "pin_code": parseInt(this.state.pincode),
            "city": this.state.city,
            "state": this.state.state,
            "country": this.state.country
          }
        }
      } else {
        address = {
          "address": {
            "address_line_1": this.state.addressline1,
            "address_line_2": this.state.addressline2,
            "pin_code": parseInt(this.state.pincode),
            "city": this.state.city,
            "state": this.state.state,
            "country": this.state.country
          }
        }
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateBuyerProfileAddressApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(address)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.buyerProfileAddressAPIEndpoint
      );

      if (this.state.buyerProfileAddressData) {
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.putApiMothod
        );
      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.exampleAPiMethod
        );
      }

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  updateBuyerProfileImg: any = async () => {
    if (this.state.buyerProfileImgFile) {
      const buyertoken = await getStorageData("buerLoginToken");

      const header = {
        "token": buyertoken,
      };

      const formData = new FormData()
      formData.append("profile_photo", this.state.buyerProfileImgFile)

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateBuyerProfileImage = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointUpdateBuyerProfile
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

    }
  }

  updateBuyerProfileBannerImg: any = async () => {
    if (this.state.buyerProfileBannerImgFile) {
      const buyertoken = await getStorageData("buerLoginToken");

      const header = {
        "token": buyertoken,
      };

      const formData = new FormData()
      formData.append("background_photo", this.state.buyerProfileBannerImgFile)

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateBuyerProfileBannerImage = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointUpdateBuyerProfile
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

    }
  }

  updateBuyerEmail: any = async () => {
    const buyertoken = await getStorageData("buerLoginToken");
    if (buyertoken) {
      const header = {
        "token": buyertoken,
      };
      const formData = new FormData()
      formData.append("updated_email", this.state.emailCopy)

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateEmail = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.buyerUpdateProfileAPIEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  buyerUpdateEmailTokenVerify: any = () => {
    const header = { "token": this.state.updateEmailToken };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateEmailTokenVerify = requestMessage.messageId;

    requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header) );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.updateEmailAPIEndPoint + `?pin=${this.state.otpStateEmail}&update=true`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateBuyerMobile: any = async () => {
    const buyertoken = await getStorageData("buerLoginToken");
    if (buyertoken) {
      const header = {
        "token": buyertoken,
      };
      const formData = new FormData()
      formData.append("updated_mobile_number", this.state.countrySelectedValue.value+this.state.mobileNumberCopy)
      formData.append("country_code", this.state.countrySelectedValue.value)

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateMobile = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.buyerUpdateProfileAPIEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  buyerUpdateMobileTokenVerify: any = () => {
      const header = { "token": this.state.updateMobileToken,
        "Content-Type": "application/json"
      };

      const pinData = { "pin": this.state.otpStateMobile, "update": true }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateMobileTokenVerify = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(pinData)
      );

      requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header) );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateMobileAPIndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateSellerChangePassword: any = async () => {
    const buyertoken = await getStorageData("buerLoginToken");
    if (buyertoken) {
      const header = {
        "token": buyertoken,
        "Content-Type": 'application/json'
      };
      const passwordData = {
        "current_password": this.state.currentPasswordCopy,
        "new_password": this.state.newPasswordCopy
    }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateBuyerPassword = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(passwordData)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updatePasswordAPIEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  updateSellerProfileBio: any = async () => {
    const buyertoken = await getStorageData("buerLoginToken");

    const header = {
      "token": buyertoken, };

    const formData = new FormData()
    formData.append("my_bio", this.state.profileBio)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateProfileBio = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), formData );

    requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.sellerUpdateProfileAPIEndPoint );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStateList = () => {
    const { country, countryList } = this.state;
    const header = {
      "Content-Type": "application/json"
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getStateListApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(
      MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.stateList}?country_code=${countryList.find((item: any) => item.name === country)?.slug}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateSellerProfileUsername: any = async () => {
    const buyertoken = await getStorageData("buerLoginToken");

    const header = {
      "token": buyertoken,
    };

    const formData = new FormData()
    formData.append("user_name", this.state.profileUsername)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateProfileUsername = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sellerUpdateProfileAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getBuyerSubcategoryData: any = async () => {
    const buyertoken = await getStorageData("buerLoginToken");
    if (buyertoken) {
      const header = {
        "token": buyertoken,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getSubCategoryApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getSubCategoryAPIEdPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  getSuggestionData: any = async () => {
    const buyertoken = await getStorageData("buerLoginToken");
    if (buyertoken) {
      const header = {
        "token": buyertoken,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getSubCategoryDataApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getSubCategoryDataAPIEdPoint}${this.state.subCategoryID}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  getCityList = () => {
    const { country, countryList, stateList, state } = this.state;

    const countrySlug = countryList.find((item: any) =>
      item.name === country)?.slug;
    const stateSlug = stateList.find((item: any) =>
      item.name === state)?.slug;

    const header = {
      "Content-Type": "application/json"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCityListApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(
      MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.cityList}?country_code=${countrySlug}&state_code=${stateSlug}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(
      MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateWishListItem = async (id: number, type: string) => {
    const buyertoken = await getStorageData("buerLoginToken");
    if (buyertoken) {
      const header = { "token": buyertoken,
        "Content-Type": "application/json"
      };
      if (type == "catalogue") { type = "product" }
      const body = {
        "data": {
          "type": type,
          "favouriteable_id": id,
        }
      }

      const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage) );

      this.apiAddToWishList = requestMessage.messageId;


      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(body));

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.favouriteItemEndPoint
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  deleteWishListItem = async (id: number) => {
    const buyertoken = await getStorageData("buerLoginToken");
    if (buyertoken) {
      const header = {
        "token": buyertoken,
        "Content-Type": "application/json"
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiDeleteFromWishList = requestMessage.messageId;

      requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.delete);

      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.favouriteItemEndPoint}/${id}`
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  getCountryList = () => {
    const header = {
      "Content-Type": "application/json"
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCountryListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.countryList);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header));

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  formatDate = (date: any) => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;
    return `${day}-${month}-${year}`;
  };

  getOrderList = async() => {
    const buyertoken = await getStorageData("buerLoginToken");
    if (buyertoken) {
      const header = {
        "token": buyertoken,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getOrderListApiCallId = requestMessage.messageId;

      let order = ''
      let finalDate = ''
      let EndPoint = ''
      const queryParams = [];
      if (this.state.orderType !== '') {
        order = this.state.orderType
        queryParams.push(`status=${order}`);
      }
      switch (this.state.dateType){
        case '1year':
          let currentDate = new Date();
          currentDate.setFullYear(currentDate.getFullYear() - 1);
          let formattedDate = this.formatDate(currentDate);
          finalDate = formattedDate;
          queryParams.push(`date=${finalDate}`);
          break;
        case '3month':
          let currentDate2 = new Date();
          currentDate2.setMonth(currentDate2.getMonth() - 3);
          let formattedDate2 = this.formatDate(currentDate2);
          finalDate = formattedDate2;
          queryParams.push(`date=${finalDate}`);
          break;
        case '30day':
          let currentDate3 = new Date();
          currentDate3.setDate(currentDate3.getDate() - 30);
          let formattedDate3 = this.formatDate(currentDate3);
          finalDate = formattedDate3;
          queryParams.push(`date=${finalDate}`);
          break;
        default:
          break;
      }
      if (this.state.orderSearch !== ''){
        queryParams.push(`search=${this.state.orderSearch}`);
      }
      const queryString = queryParams.length > 0 ? queryParams.join("&") : "";
      if (queryString === '') {
        EndPoint = configJSON.orderList
      } else {
        EndPoint = configJSON.orderList+`?${queryString}`
      }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), EndPoint);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header));

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType);

    runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  apiCall = async (apiReqData: ApiPayloadType) => {
    let token = await getStorageData("buerLoginToken");
    const {  method, endPoint, body, type, contentType } = apiReqData;
    const header = {
        token: token,
        "Content-Type": contentType,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
        method
    );
    
    body && type != 'formData' ?
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        )
        : requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
        );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
};

  getFollowSellerApiCall = async () => {
    this.getFollowSellerApiCallID = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getFollowSellarListEndPoint}?search=${this.state.searchFollowList}`,
      type: ''
    })
  };

  getFollowSellerCallback = (responseJson: any) => {
    if (responseJson.data) {
      if(this.state.openModalFollowing){
        this.setState({ sallerFollowingModalData: responseJson });
      } else{
        this.setState({ sallerFollowingData: responseJson, sallerFollowingModalData: responseJson });
      }
    } else {
      if (this.state.openModalFollowing) {
        this.setState({
          sallerFollowingModalData: {
            data: []
          }
        });
      } else {
        this.setState({
          sallerFollowingData: {
            data: [],
            meta: {
              message: "",
              total_following: 0
            }
          },
          sallerFollowingModalData: {
            data: []
          }
        });
      }

    }
  }

  unFollowSellerApiCall = async (id: number,uniID:string) => {
    const updatedData = this.state.sallerFollowingModalData.data.map((item) =>
      item.id === uniID ? { ...item, type: "unfollow" } : item
    );
    this.unFollowSellerApiCallID = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.delete,
      endPoint: `${configJSON.unFollowSellarEndPoint}/${id}`,
      type: ''
    });
    this.setState({sallerFollowingModalData : {...this.state.sallerFollowingModalData,data:updatedData}})
  }

  unFollowSellerCallback = (responseJson: any) => {
    if (responseJson) {
      console.log(responseJson,'check jsonnnnnnn');
    }
  }

  handleFolloersListSearch = (event:any) => {
    this.setState({ searchFollowList: event.target.value})
  }

  clickFollowButton = async (id: number,uniID: string) => {
    const updatedData = this.state.sallerFollowingModalData.data.map((item) =>
      item.id === uniID ? { ...item, type: "follow" } : item
    );
   
    // Make the API call to follow/unfollow the user
    this.followSellerApiCallID = await this.apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.exampleAPiMethod,
        endPoint: configJSON.followSellar,
        body: {
            "data": {
                "attributes": {
                    "followable_id": id
                }
            }
        },
        type: ''
    });
    this.setState({ sallerFollowingModalData : {...this.state.sallerFollowingModalData,data:updatedData}})
};

getFollowSellerCallbackChange = (responseJson: any) => {
  this.setState({ hideShow : 0})
}

  handleProductDescription = (productId: string | number) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ProductDescription");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
    this.send(message);
  };

  handleBackToProfile = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "UserProfiles");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  // Customizable Area End
}
