// Customizable Area Start
import React from "react";

import {
    Button,
    InputAdornment,
    IconButton,
    // Customizable Area Start
    styled,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import '../assets/css/style.css'
import OtpInputField from "../../../components/src/OtpInputField";
import Loader from "../../../components/src/Loader.web";

import {
    profileImg,
    EditGrayIc,
    EditYellowIc,
    star,
    CloseIc,
    PasswordShowIc,
    PasswordHideIc,
    SuccessIc,
    bannerImageBlankSeller,
    AddYellowIc,
    arrowLeftIc
} from "./assets";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
const truthyValue = (key: any) => {
    if (key !== "" || key !== null || !key.length) {
        return key
    } else {
        return ""
    }     
}
// Customizable Area End

import CustomisableUserProfilesController, {
    Props,
} from "./CustomisableUserProfilesController";
import StayTuned from "../../../components/src/StayTuned.web";
import MessageModalWeb from "../../../components/src/MessageModal.web";

export default class UserProfilesEdit extends CustomisableUserProfilesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    pwdError() {
        return (this.state.pwdError !== "" && (
            <div className="red-text">{this.state.pwdError}</div>
        ))
    }

    phoneError() {
        return (this.state.phoneError !== "" && (
            <div className="red-text">{this.state.phoneError}</div>
        ))
    }

    emailError() {
        return (this.state.emailError !== "" && (
            <div className="red-text">{this.state.emailError}</div>
        ))
    }

    addressline1Error() {
        return (this.state.addressline1Error !== "" && (
            <div className="red-text">{this.state.addressline1Error}</div>
        ))
    }

    addressline2Error() {
        return (this.state.addressline2Error !== "" && (
            <div className="red-text">{this.state.addressline2Error}</div>
        ))
    }

    pincodeError() {
        return (this.state.pincodeError !== "" && (
            <div className="red-text">{this.state.pincodeError}</div>
        ))
    }

    cityError() {
        return (this.state.cityError !== "" && (
            <div className="red-text">{this.state.cityError}</div>
        ))
    }

    stateError() {
        return (this.state.stateError !== "" && (
            <div className="red-text">{this.state.stateError}</div>
        ))
    }

    countryError() {
        return (this.state.countryError !== "" && (
            <div className="red-text">{this.state.countryError}</div>
        ))
    }

    addressError() {
        return (this.state.addressError !== "" && (
            <div className="red-text">{this.state.addressError}</div>
        ))
    }

    otpError() {
        return (this.state.otpError !== "" && (
            <div className="red-text">{this.state.otpError}</div>
        ))
    }

    newPwdError() {
        return (this.state.newPwdError !== "" && (
            <div className="red-text">{this.state.newPwdError}</div>
        ))
    }

    currentPwdError() {
        return (this.state.currentPwdError !== "" && (
            <div className="red-text">{this.state.currentPwdError}</div>
        ))
    }

    reNewPasswordError() {
        return (this.state.reNewPasswordError !== "" && (
            <div className="red-text">{this.state.reNewPasswordError}</div>
        ))
    }

    profileBioError() {
        return (this.state.profileBioError !== "" && (
            <div className="red-text">{this.state.profileBioError}</div>
        ))
    }

    profileUsernameError() {
        return (this.state.profileUsernameError !== "" && (
            <div className="red-text">{this.state.profileUsernameError}</div>
        ))
    }

    returnBuyerAdderss = () => {
        let fullAddress = "";
        if (this.state.buyerProfileAddressData?.attributes) {
            const addressLine1 = this.state.buyerProfileAddressData.attributes.address_line_1 ? this.state.buyerProfileAddressData.attributes.address_line_1 : ""
            const addressLine2 = this.state.buyerProfileAddressData.attributes.address_line_2 ? this.state.buyerProfileAddressData.attributes.address_line_2 : ""
            const pinCode = this.state.buyerProfileAddressData.attributes.pin_code ? this.state.buyerProfileAddressData.attributes.pin_code : ""
            const city = this.state.buyerProfileAddressData.attributes.city ? this.state.buyerProfileAddressData.attributes.city : ""
            const stateName = this.state.buyerProfileAddressData.attributes.state ? this.state.buyerProfileAddressData.attributes.state : ""
            const countryName = this.state.buyerProfileAddressData.attributes.country ? this.state.buyerProfileAddressData.attributes.country : ""

            fullAddress = `${addressLine1}, ${addressLine2}, ${pinCode}, ${city}, ${stateName}, ${countryName}`
        }
        return fullAddress
    }

    abstractBuyerProfileData =() =>{

        let userName = ""
        let userProfilePhoto = ""
        let full_name = ""
        let email = ""
        let phone_number = ""
        let my_bio = ""
        let backgroundphoto = ""
        if(this.state.buyerProfileData){
            userName = truthyValue(this.state.buyerProfileData.attributes.user_name)
            userProfilePhoto = truthyValue(this.state.buyerProfileData.attributes.profile_photo)
            full_name = truthyValue(this.state.buyerProfileData.attributes.full_name)
            email = truthyValue(this.state.buyerProfileData.attributes.email)
            phone_number = truthyValue(this.state.buyerProfileData.attributes.full_phone_number)
            my_bio = truthyValue(this.state.buyerProfileData.attributes.my_bio)
            backgroundphoto = truthyValue(this.state.buyerProfileData.attributes.background_photo)
        }

        return { userName, userProfilePhoto, full_name, email, phone_number, my_bio, backgroundphoto }

    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { userName, userProfilePhoto, full_name, email, phone_number, my_bio, backgroundphoto } = this.abstractBuyerProfileData()
        const {country, countryList, stateList, state, cityList, city} = this.state
        // Customizable Area End
        return (
            // Customizable Area Start
            <div className="main-div">
                 <div id="myref"></div>
                <section className="banner-section">
                    {backgroundphoto ?
                        <div className="banner-img-wrap">
                            <img src={backgroundphoto} alt="banner Img" />
                            <img src={arrowLeftIc} alt="left arrow" className="back-button" data-test-id="navigateTestId" onClick={this.handleBackToProfile} />
                            <IconButton className="edit-icon">
                                <img src={EditYellowIc} alt="Edit" />
                                <input
                                    accept="image/*"
                                    id="upload-avatar-pic"
                                    type="file"
                                    className="upload"
                                    data-test-id="uploadFileBackground"
                                    onChange={this.handleBuyerBannerImage}
                                />
                            </IconButton>
                        </div>
                        :
                        <div className="banner-img-wrap no-bg">
                            <img className="no-bg" src={bannerImageBlankSeller} alt="banner Img" />
                            <img src={arrowLeftIc} alt="left arrow" data-test-id="navigateTestId" className="back-button" onClick={this.handleBackToProfile} />
                            <IconButton className="edit-icon">
                                <img src={AddYellowIc} alt="Edit" />
                                <input
                                    accept="image/*"
                                    id="upload-avatar-pic"
                                    type="file"
                                    className="upload"
                                    data-test-id="uploadFileBackground"
                                    onChange={this.handleBuyerBannerImage}
                                />
                            </IconButton>
                        </div>
                    }
                    <div className="profile-detail">
                        <div className="container">
                            <div className="profile-detail-inner">
                                <div className="profile-photo-wrap">
                                    <Avatar src={userProfilePhoto}  className="profile-photo">
                                    </Avatar>
                                    <IconButton className="edit-icon">
                                        {userProfilePhoto ?
                                            <img src={EditYellowIc} alt="Edit" />
                                            :
                                            <img src={AddYellowIc} alt="Edit" />
                                        }
                                        <input
                                            accept="image/*"
                                            id="upload-avatar-pic"
                                            type="file"
                                            className="upload"
                                            data-test-id="uploadFile"
                                            onChange={this.handleBuyerProfileImage}
                                        />
                                    </IconButton>
                                </div>
                                <div className="profile-description-wrap">
                                    <div className="name-wrap">
                                        <span className="name">{full_name}</span>
                                        <span className="review">
                                            4.5
                                            <i>
                                                <img src={star} alt="Review" />
                                            </i>
                                        </span>
                                    </div>
                                    <div className="following-followers-wrap">
                                        <div className="title-number-wrap" data-test-id="followers"
                                            onClick={() => this.openStayTunedBox()}>
                                            <span className="title">Followers</span>
                                            <span className="number">12k</span>
                                        </div>
                                        <div className="title-number-wrap"
                                            onClick={() => this.openStayTunedBox()}
                                                data-test-id="following" >
                                            <span className="title">Following</span>
                                            <span className="number">1.5k</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="personal-detail-section">
                    <div className="container">
                        <div className="personal-detail-wrap">
                            <div className="left-wrap">
                                <div className="editable-form-group">
                                    <label className="editable-label">
                                        User name
                                        <IconButton className="edit-icon">
                                            <img src={EditGrayIc} alt="Edit" data-test-id="changeUsernameModal" onClick={() => this.handleOpenModalUsername()} />
                                        </IconButton>
                                    </label>
                                    <TextField
                                        className="editable-control"
                                        data-test-id="userNameText"
                                        value={userName}
                                        InputProps={{readOnly: true}}
                                        onChange={(e) => this.setState({ username: e.target.value })}
                                    />
                                </div>
                                <div className="editable-form-group">
                                    <label className="editable-label">
                                        Email
                                        <IconButton className="edit-icon" data-test-id="changeEmailModal" onClick={() => this.handleOpenModalChangeEmail()}>
                                            <img src={EditGrayIc} alt="Edit" />
                                        </IconButton>
                                    </label>
                                    <TextField
                                        className="editable-control medium"
                                        value={email}
                                        InputProps={{readOnly: true}}
                                    />
                                </div>
                                <div className="editable-form-group">
                                    <label className="editable-label">
                                        Mobile number
                                        <IconButton className="edit-icon" data-test-id="mobileModal" onClick={() => this.handleOpenModalMobile()}>
                                            <img src={EditGrayIc} alt="Edit" />
                                        </IconButton>
                                    </label>
                                    <TextField className="editable-control medium" value={phone_number}
                                     InputProps={{readOnly: true}} />
                                </div>
                            </div>
                            <div className="right-wrap">
                                <div className="right-inner">
                                    <div className="editable-form-group">
                                        <label className="editable-label large">
                                            Bio
                                            <IconButton className="edit-icon">
                                                <img src={EditGrayIc} alt="Edit" data-test-id="bioModal" onClick={()=>this.handleOpenModalBio()} />
                                            </IconButton>
                                        </label>
                                        <TextField
                                            className="editable-control no-underline small"
                                            value={my_bio}
                                            multiline
                                            maxRows={10}
                                            InputProps={{readOnly: true}}
                                        />
                                    </div>
                                    <div className="editable-form-group">
                                        <label className="editable-label large">
                                            Address
                                            <IconButton className="edit-icon" data-test-id="changeAddressModal" onClick={() => this.handleOpenModalChangeAddress()}>
                                                <img src={EditGrayIc} alt="Edit" />
                                            </IconButton>
                                        </label>
                                        <TextField
                                            className="editable-control no-underline medium"
                                            value={this.returnBuyerAdderss()}
                                            multiline
                                            maxRows={5}
                                        />
                                    </div>
                                    <div className="password-account-wrap">
                                        <div className="password-wrap column">
                                            <span className="title">Change Password </span>
                                            <span>Remember, a strong password includes a mix of letters, numbers, and special characters. Keep your account safe and sound!</span>
                                            <div className="btn-wrap">
                                                <Button className="custom-button primary-yellow large" data-test-id="changePasswordModal" onClick={() => this.handleOpenModalEnterChangePassword()}>Change Password</Button>
                                            </div>
                                        </div>
                                        <div className="account-wrap column">
                                            <span className="title">Account Removal </span>
                                            <span>Please note that this action is irreversible, and all your data will be lost. If you change your mind, you'll need to create a new account or If you'd like to take a break, you can temporarily disable your account without losing any data. </span>
                                            <div className="btn-wrap">
                                                <Button className="custom-button primary-red" data-test-id="disableAccountModal" onClick={() => this.openStayTunedBox()}>Disable Account </Button>
                                                <Button className="custom-button outline-yellow" data-test-id="deleteAccountModal" onClick={() => this.openStayTunedBox()}>Delete Account</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Dialog
                    open={this.state.openModalFollowing}
                    data-test-id="followingClose"
                    onClose={() => this.handleCloseModalFollowing()}
                    className="custom-dialog"
                >
                    <DialogTitle className="title-wrap">
                        <span>Following</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="dialogCloseFollowing"
                            onClick={() => this.handleCloseModalFollowing()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 11</span>
                                    <span className="name">Vaibhav Verm</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow font-gray">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 12</span>
                                    <span className="name">Vaibhav Verma</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow font-gray">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 13</span>
                                    <span className="name">Vaibhav Verma</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow font-gray">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 14</span>
                                    <span className="name">Vaibhav Verma</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow font-gray">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 15</span>
                                    <span className="name">Vaibhav Verma</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow font-gray">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 16</span>
                                    <span className="name">Vaibhav Verma</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow font-gray">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 17</span>
                                    <span className="name">Vaibhav Verma</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow font-gray">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 18</span>
                                    <span className="name">Vaibhav Verma</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow font-gray">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 19</span>
                                    <span className="name">Vaibhav Verma</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow font-gray">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 101</span>
                                    <span className="name">Vaibhav Verma</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow font-gray">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 102</span>
                                    <span className="name">Vaibhav Verma</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow font-gray">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 103</span>
                                    <span className="name">Vaibhav Verma</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow font-gray">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.openModalFollowers}
                    data-test-id="followersClose"
                    onClose={() => this.handleCloseModalFollowers()}
                    className="custom-dialog"
                >
                    <DialogTitle className="title-wrap">
                        <span>Followers</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="dialogCloseFollowers"
                            onClick={() => this.handleCloseModalFollowers()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 11</span>
                                    <span className="name">Vaibhav Verm</span>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 12</span>
                                    <span className="name">Vaibhav Verma</span>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 13</span>
                                    <span className="name">Vaibhav Verma</span>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 14</span>
                                    <span className="name">Vaibhav Verma</span>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 15</span>
                                    <span className="name">Vaibhav Verma</span>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 16</span>
                                    <span className="name">Vaibhav Verma</span>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 17</span>
                                    <span className="name">Vaibhav Verma</span>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 18</span>
                                    <span className="name">Vaibhav Verma</span>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 19</span>
                                    <span className="name">Vaibhav Verma</span>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 101</span>
                                    <span className="name">Vaibhav Verma</span>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 102</span>
                                    <span className="name">Vaibhav Verma</span>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 103</span>
                                    <span className="name">Vaibhav Verma</span>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.openModalMobile}
                    data-test-id="mobileClose"
                    onClose={() => this.handleCloseModalMobile()}
                    className="custom-dialog other-dialog">
                    <DialogTitle className="title-wrap">
                        <span>Add Mobile number</span>
                        <IconButton
                            className="close-btn"
                            data-test-id="dialogCloseMobile"
                            aria-label="close"
                            onClick={() => this.handleCloseModalMobile()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <p className="gray-text">Enter your 10 digit mobile number to get OTP to your register mobile number</p>
                            <div className="custom-form-group">
                                <div className="country-code-wrap">
                                    <div className="country-wrapper">
                                        <FormControl 
                                            variant="outlined" 
                                            className="custom-outline-form-control">
                                            <InputLabel>Code</InputLabel>
                                            <Select
                                                label="Code"
                                                data-test-id="countryChange"
                                                onChange={this.handleChangeCountry}
                                                value={this.state.countrySelectedValue.value}
                                            >                                                
                                             {this.state.dataSource.length > 0 ? this.state.dataSource.map((item, index: number) => (
                                                    <MenuItem value={item.attributes.country_code} data-test-id={`countryValue${index}`} key={index}>
                                                        <CountrySpan>{item.attributes.emoji_flag}</CountrySpan>
                                                        {item.id} {item.attributes.country_code}
                                                    </MenuItem>
                                                )) : <></>}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="number-wrapper">
                                        <TextField
                                            label="Mobile number"
                                            className="custom-outline-form-control"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={this.state.mobileNumber}
                                            variant="outlined"
                                            type="number"
                                            data-test-id="mobileNumberText"
                                            onChange={this.handleMobileNumberChange}
                                        />
                                        {this.phoneError()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="btn-wrap">
                        <Button data-test-id="mobileSendOtp"
                            onClick={() => this.checkValidationModalMobile()}
                            className="custom-button primary-yellow large full-width"
                        >
                            Send OTP
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openModalDisableAccout}
                    data-test-id="disableAccountClose"
                    onClose={() => this.handleCloseModalDisableAccount()}
                    className="custom-dialog other-dialog">
                    <DialogTitle className="title-wrap">
                        <span>
                            Disable your account ?
                        </span>
                        <IconButton
                            className="close-btn"
                            aria-label="close"
                            data-test-id="dialogCloseDisableAccount"
                            onClick={() => this.handleCloseModalDisableAccount()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <p className="gray-text">Are you sure you want to disable your account?</p>
                            <div className="listing">
                                <span className="list-title">
                                    While your account is disabled:
                                </span>
                                <ul><li>Your profile will be hidden.</li>
                                    <li>You won't receive any notifications.</li>
                                    <li>You can reactivate anytime.</li>
                                </ul>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="btn-wrap">
                        <Button
                            onClick={() => this.handleCloseModalDisableAccount()}
                            data-test-id="dialogDisableAccountCancelBtn"
                            className="custom-button transparent-button"
                        >
                            Cancel
                        </Button>
                        <Button
                            className="custom-button transparent-button red-btn"
                        >
                            Disable
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    data-test-id="deleteAccountClose"
                    open={this.state.openModalDeleteAccount}
                    onClose={() => this.handleCloseModalDeleteAccount()}
                    className="custom-dialog other-dialog">
                    <DialogTitle className="title-wrap">
                        <span>Delete your account permanently ?</span>
                        <IconButton
                            data-test-id="dialogCloseDeleteAccount"
                            aria-label="close"
                            className="close-btn"
                            onClick={() => this.handleCloseModalDeleteAccount()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <p className="gray-text">This action is irreversible!
                            </p>
                            <div className="listing">
                                <ul><li>All your data will be permanently erased.</li>
                                    <li>You won't be able to recover your account.</li>
                                    <li>All your content and history will be lost.</li></ul>
                            </div>
                            <div className="listing">
                                <span className="list-title">Please type your password to confirm:</span>
                            </div>
                            <div className="custom-form-group">
                                <TextField
                                    className="custom-outline-form-control"
                                    variant="outlined"
                                    label="Password"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    data-test-id="deleteAccountPasswordText"
                                    type="password"
                                    onChange={this.handlePasswordDeleteAccount}
                                />
                                {this.pwdError()}
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="btn-wrap">
                        <Button
                            data-test-id="deleteAccountCancelBtn"
                            onClick={() => this.handleCloseModalDeleteAccount()}
                            className="custom-button transparent-button"
                        >
                            Cancel
                        </Button>
                        <Button
                            data-test-id="deleteAccountDeleteBtn"
                            onClick={() => this.checkValidationDeleteAccount()}
                            className="custom-button transparent-button red-btn"
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openModalChangeEmail}
                    data-test-id="changeEmailClose"
                    onClose={() => this.handleCloseModalChangeEmail()}
                    className="custom-dialog other-dialog">
                    <DialogTitle className="title-wrap">
                        <span>Add Email ID</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="dialogCloseChangeEmail"
                            onClick={() => this.handleCloseModalChangeEmail()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <p className="gray-text">
                                Enter your Email ID to get OTP on your register email address 
                            </p>
                            <div className="custom-form-group">
                                <TextField
                                    label="Email Id"
                                    variant="outlined"
                                    className="custom-outline-form-control"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    data-test-id="changeEmailText"
                                    onChange={this.handleChangeEmail}
                                />
                                {this.emailError()}
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="btn-wrap">
                        <Button
                            data-test-id="dialogChangeEmailOtpBtn"
                            onClick={() => this.checkValidationModalEmail()}
                            className="custom-button primary-yellow large full-width"
                        >
                            Send OTP
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openModalChangeAddress}
                    data-test-id="changeAddressClose"
                    onClose={() => this.handleCloseModalChangeAddress()}
                    className="custom-dialog other-dialog">
                    <DialogTitle className="title-wrap">
                        <span>Change drop off address</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="dialogCloseChangeAddress"
                            onClick={() => this.handleCloseModalChangeAddress()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <p className="gray-text">
                                Update your drop off address by entering it.
                            </p>
                            <div className="custom-form-group">
                                <TextField
                                    label="Address line 1"
                                    variant="outlined"
                                    className="custom-outline-form-control"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={this.state.addressline1}
                                    data-test-id="address1Text"
                                    onChange={this.handleChangeAddressline1}
                                />
                                {this.addressline1Error()}
                            </div>
                            <div className="custom-form-group">
                                <TextField
                                    label="Address line 2"
                                    variant="outlined"
                                    className="custom-outline-form-control"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={this.state.addressline2}
                                    data-test-id="address2Text"
                                    onChange={this.handleChangeAddressline2}
                                />
                                {this.addressline2Error()}
                            </div>
                            <div className="custom-form-group">
                                <TextField
                                    label="Pin Code"
                                    variant="outlined"
                                    className="custom-outline-form-control"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    defaultValue={this.state.pincode}
                                    data-test-id="pincodeText"
                                    onChange={this.handleChangePincode}
                                />
                                {this.pincodeError()}
                            </div>
                            <div className="custom-form-group">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={countryList.map((item: any) => item.name)}
                                    getOptionLabel={(option: any) => option}
                                    style={{ width: '100%', maxWidth: '509px' }}
                                    onBlur={this.ValidateCountry}
                                    onChange={this.handleCountryChange}
                                    data-test-id="countryText"
                                    value={country}
                                    className="auto-complete-list"
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            placeholder="Country"
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <ExpandMoreIcon style={{ height: '32px', width: '32px' }} />
                                                ),
                                            }}
                                        />)}
                                />
                                {this.countryError()}
                            </div>
                            <div className="custom-form-group">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={stateList.map((item: any) => item.name)}
                                    getOptionLabel={(option: any) => option}
                                    style={{ width: '100%', maxWidth: '509px' }}
                                    onBlur={this.ValidateState}
                                    value={state}
                                    className="auto-complete-list"
                                    data-test-id="stateText"
                                    disabled={stateList.length === 0}
                                    onChange={this.handleStateChange}
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            placeholder="State"
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <ExpandMoreIcon style={{ height: '32px', width: '32px' }} />
                                                ),
                                            }}
                                        />)}
                                />
                                {this.stateError()}
                            </div>
                            <div className="custom-form-group">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={cityList}
                                    getOptionLabel={(option: any) => option}
                                    onBlur={this.ValidateCity}
                                    onChange={this.handleCityChange}
                                    value={city}
                                    data-test-id="cityText"
                                    className="auto-complete-list"
                                    disabled={cityList.length === 0}
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            placeholder="City"
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <ExpandMoreIcon style={{ height: '32px', width: '32px' }} />
                                                ),
                                            }}
                                        />)}
                                />
                                {this.cityError()}
                            </div>
                            {this.addressError()}
                        </div>
                    </DialogContent>
                    <DialogActions className="btn-wrap">
                        <Button
                            data-test-id="dialogChangeAddressValidateBtn"
                            onClick={() => this.checkValidationAddressChange()}
                            className="custom-button primary-yellow large full-width"
                        >
                            Verify
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openModalEmailOtp}
                    data-test-id="emailOtpClose"
                    onClose={() => this.handleCloseModalEmailOtp()}
                    className="custom-dialog other-dialog">
                    <DialogTitle className="title-wrap">
                        <span>
                            Verify email
                        </span>
                        <IconButton
                            className="close-btn"
                            aria-label="close"
                            onClick={() => this.handleCloseModalEmailOtp()}
                            data-test-id="dialogCloseEmailOtp"
                        >
                            <img 
                                src={CloseIc} 
                                alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <p className="gray-text"> Enter the OTP that was sent to your email address. </p>
                            <div className="custom-form-group">
                                <OtpInputField value={this.state.otpStateEmail} valueLength={6} data-test-id="emailOtp" onChange={this.handleOTPStateEmail} />
                                {this.otpError()}
                            </div>
                            <span onClick={()=>this.updateBuyerEmail()} className="custom-primary-link">Send the code again</span>
                        </div>
                    </DialogContent>
                    <DialogActions className="btn-wrap">
                        <Button
                            data-test-id="dialogEmailOtpVeifyBtn"
                            onClick={() => this.checkValidationOtpEmail()}
                            className="custom-button primary-yellow large full-width"
                        >
                            Verify
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openModalMobileOtp}
                    data-test-id="mobileOtpClose"
                    onClose={() => this.handleCloseModalMobileOtp()}
                    className="custom-dialog other-dialog">
                    <DialogTitle className="title-wrap">
                        <span>
                            Verify mobile number</span>
                        <IconButton
                            className="close-btn"
                            onClick={() => this.handleCloseModalMobileOtp()}
                            aria-label="close"
                            data-test-id="dialogCloseMobileOtp"
                        >
                            <img src={CloseIc} 
                                alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <p className="gray-text"> Enter the OTP that was sent to your mobile number </p>
                            <div className="custom-form-group">
                                <OtpInputField value={this.state.otpStateMobile} valueLength={6} data-test-id="mobileOtp" onChange={this.handleOTPStateMobile} />
                                {this.otpError()}
                            </div>
                            <span onClick={()=>this.updateBuyerMobile()} className="custom-primary-link">Send the code again</span>
                        </div>
                    </DialogContent>
                    <DialogActions className="btn-wrap">
                        <Button
                            data-test-id="dialogMobileOtpVerifyBtn"
                            onClick={() => this.checkValidationOtpMobile()}
                            className="custom-button primary-yellow large full-width"
                        >
                            Verify
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openModalEnterChangePassword}
                    data-test-id="changePasswordClose"
                    onClose={() => this.handleCloseModalEnterChangePassword()}
                    className="custom-dialog other-dialog">
                    <DialogTitle className="title-wrap">
                        <span>Create new password</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="dialogCloseChangePassword"
                            onClick={() => this.handleCloseModalEnterChangePassword()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <p className="gray-text">
                            Your password must be at least six characters and should include a combination of numbers, letters and special characters (!$@%).
                            </p>
                            <div className="custom-form-group">
                                <TextField
                                    label="Current password"
                                    variant="outlined"
                                    className="custom-outline-form-control"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    data-test-id="currentPassowrdText"
                                    type={this.state.showCurrenPassword ? 'text' : 'password'}
                                    onChange={this.handleCurrentPasswordChangePassword}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment 
                                                position="end">
                                                <IconButton 
                                                    edge="end" 
                                                    onClick={this.handleCurrenPasswordShow}>
                                                    {this.state.showCurrenPassword 
                                                        ? <img src={PasswordHideIc} alt="Password Show" /> 
                                                        : <img src={PasswordShowIc} alt="Password Hide" />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {this.currentPwdError()}
                            </div>
                            <div className="custom-form-group">
                                <TextField
                                    variant="outlined"
                                    label="New password"
                                    InputLabelProps={{ shrink: true, }}
                                    className="custom-outline-form-control"
                                    type={this.state.showNewPassword 
                                        ? 'text' 
                                        : 'password'}
                                    onChange={this.handleNewPasswordChangePassword}
                                    data-test-id="newPassowrdText"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" 
                                                    onClick={this.handleNewPasswordShow}>
                                                    {this.state.showNewPassword ? 
                                                        <img src={PasswordHideIc} alt="Password Show" /> : 
                                                        <img src={PasswordShowIc} alt="Password Hide" />
                                                    }
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {this.newPwdError()}
                            </div>
                            <div className="custom-form-group">
                                <TextField
                                    className="custom-outline-form-control"
                                    label="Re-enter new password"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true, }}
                                    type={this.state.showReNewPassword 
                                        ? 'text' 
                                        : 'password'
                                    }
                                    onChange={this.handleReEnterNewPasswordChangePassword}
                                    data-test-id="renewPassowrdText"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton 
                                                    edge="end" onClick={this.handleReNewPasswordShow}>
                                                    {this.state.showReNewPassword 
                                                        ? <img src={PasswordHideIc} 
                                                                alt="Password Show" /> 
                                                        : <img src={PasswordShowIc} 
                                                                alt="Password Hide" />
                                                    }
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {this.reNewPasswordError()}
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="btn-wrap">
                        <Button
                            onClick={() => this.checkValidationPasswordChange()}
                            data-test-id="changePasswordSaveBtn"
                            className="custom-button primary-yellow large full-width"
                        >
                            Save changes
                        </Button>
                    </DialogActions>
                    <Loader loading={this.state.isLoading} />
                </Dialog>
                <Dialog
                    open={this.state.openModalAddressSuccessfull}
                    data-test-id="addressSucessClose"
                    onClose={() => this.handleCloseModalAddressSuccessfull()}
                    className="custom-dialog success-dialog"
                >
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <div className="img-wrap">
                                <img src={SuccessIc} alt="Success" />
                            </div>
                            <div className="content-wrap">
                                <span className="title">Successfully added</span>
                                <div className="content">
                                    <p>Your drop-off address have been successfully added.</p>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    data-test-id="passwordSucessClose"
                    open={this.state.openModalPasswordSuccessfull}
                    className="custom-dialog success-dialog"
                    onClose={() => this.handleCloseModalPasswordSuccessfull()}
                >
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <div className="img-wrap">
                                <img 
                                    src={SuccessIc} 
                                    alt="Success" />
                            </div>
                            <div className="content-wrap">
                                <span className="title">Successfully added</span>
                                <div className="content">
                                    <p>Your password has been successfully changed.</p>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    className="custom-dialog other-dialog"
                    data-test-id="bioClose"
                    open={this.state.openModalBio}
                    onClose={() => this.handleCloseModalBio()}>
                    <DialogTitle className="title-wrap gray-text">
                        <span>Change Bio</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="dialogCloseBio"
                            onClick={() => this.handleCloseModalBio()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <p className="gray-text">
                                Enter your Bio
                            </p>
                            <div className="custom-form-group">
                                <TextField
                                    label="Bio"
                                    variant="outlined"
                                    className="custom-outline-form-control"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    defaultValue={my_bio}
                                    onChange={this.handleChangeProfileBio}
                                    multiline
                                    rows={4}
                                    data-test-id="profileBio"
                                />
                                {this.profileBioError()}
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="btn-wrap">
                        <Button
                            data-test-id="dialogBioSaveBtn"
                            onClick={() => this.checkValidationBioChange()}
                            className="custom-button primary-yellow large full-width"
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    className="custom-dialog other-dialog"
                    data-test-id="usernameClose"
                    open={this.state.openModalUsername}
                    onClose={() => this.handleCloseModalUsername()}>
                    <DialogTitle className="title-wrap gray-text">
                        <span>Change Username</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="dialogCloseUsername"
                            onClick={() => this.handleCloseModalUsername()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <p className="gray-text">
                                Enter your new Username!
                            </p>
                            <div className="custom-form-group">
                                <TextField
                                    label="Username"
                                    variant="outlined"
                                    className="custom-outline-form-control"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    defaultValue={userName}
                                    onChange={this.handleChangeProfileUsername}
                                    data-test-id="profileUsername"
                                />
                                {this.profileUsernameError()}
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="btn-wrap">
                        <Button
                            data-test-id="dialogUsernameSaveBtn"
                            onClick={() => this.checkValidationUsernameChange()}
                            className="custom-button primary-yellow large full-width"
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                <StayTuned openTunedBox={this.state.openTunedBox} data-test-id="closeStayTuned" closeTunedBox={this.closeTunedBox} />
                <MessageModalWeb data-test-id="errorMessage" displayPopup={this.state.errorModal} closeErrorPopup={this.errorModalClose} errorMessage={this.state.imageError} />
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const CountrySpan = styled('span')({
    fontFamily: "'Segoe UI Emoji', 'Apple Color Emoji', 'Roboto', sans-serif !important",
    marginRight: '5px',
});
// Customizable Area End
// Customizable Area Start